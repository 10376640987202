import { Controller } from "@hotwired/stimulus"
import { Splide } from "@splidejs/splide";

export default class CardPickerController extends Controller {
  static targets = ["navigation", "card", "panel"];
  static values = {
    url: String
  }

  fetchNewCardView(cardType) {
    if (this.urlValue) {
      if (isDevEnvironment()) {
        console.log(`[DEV] Fetching new card view for ${cardType}`);
      }

      console.log(`${this.urlValue}?card_type=${cardType}`)
      fetch(`${this.urlValue}?card_type=${cardType}`, {
        method: "GET",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "text/html",
          "Accept": "text/html",
        }
      }).then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error(`[DEV] Response not ok: ${response.status}`);
        }
      }).then((data) => {
        if (isDevEnvironment()) {
          console.log(`[DEV] Successfully fetched new card view for ${cardType}`);
        }

        const panel = this.getPanelByType(cardType);
        if (panel) {
          const fragment = document.createRange().createContextualFragment(data);
          panel.innerHTML = "";
          panel.appendChild(fragment);
        }

      }).catch((error) => {
        console.error(`[DEV] Error: ${error}`);
      });
    }
  }

  getPanelByIndex(cardIndex) {
    return this.panelTargets[cardIndex];
  }

  getPanelByType(cardType) {
    return this.panelTargets.find((panel) => {
      return panel.dataset.type == cardType;
    });
  }

  setActiveCard(card) {
    const cardIndex = card.dataset.index;
    const panel = this.getPanelByIndex(cardIndex);
    card.classList.add("is-active");
    card.ariaExpanded = true;
    this.element.dataset.activeCardType = card.dataset.type;    
    if (panel) {
      panel.classList.add("is-active");
      panel.ariaHidden = false;
      panel.focus();
    }

    this.cardTargets.forEach((otherCard) => {
      if (otherCard != card) {
        otherCard.classList.remove("is-active");
        otherCard.ariaExpanded = false;

        const otherCardIndex = otherCard.dataset.index;
        const otherPanel = this.getPanelByIndex(otherCardIndex);

        if (otherPanel) {
          otherPanel.classList.remove("is-active");
          otherPanel.ariaHidden = true;
        }
      }
    });
  }

  rerandom(){
    this.fetchNewCardView("random")
  }

  initialize() {
    this.splide = new Splide(this.navigationTarget, {
      type: "slide",
      perPage: 3,
      perMove: 1,
      pagination: false,
      arrows: false,
      drag: false,
      breakpoints: {
        750: {
          perPage: 1,
          perMove: 1,
          drag: true,
          pagination: true,
        }
      }
    });

    this.splide.mount();

    this.cardTargets.forEach((card) => {
      card.addEventListener("click", (event) => {
        event.preventDefault();
        if (!card.classList.contains("is-active")) {
          this.setActiveCard(card);
          const panel = this.getPanelByIndex(card.dataset.index);

          if (panel && panel.innerHTML == "") {
            this.fetchNewCardView(card.dataset.type);
          }
        }
      });
    });

    if (isDevEnvironment()) {
      console.info(`[DEV] Card Picker Controller Initialized`);
    }
  }
}
